import { push } from "connected-react-router";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from 'uuid';
import ExtendedAxios from '@featurefm/common/helpers/axios';
import mixpanel from '../../utils/mixpanel'

import {
  OKTA_IDP,
  OKTA_CLIENTID,
  SOUNDCLOUD_REDIRECT,
  SOUNDCLOUD_CLIENT,
  FACEBOOK_APP,
  OKTA_URL,
  FFM_CONSOLE_API_URL,
  FFM_CONSOLE_NEXT_WEB_URL,
  FFM_CONSOLE_WEB_URL,
  FFM_CONSOLE_NEXT_REDIRECT_PATH,
  FFM_COOKIE_DOMAIN,
} from "@featurefm/common/config/env";

import { addProtocolToUrl } from "@featurefm/common/helpers/url";

import {
  all,
  takeEvery,
  put,
  fork,
  call,
  select
} from "redux-saga/effects";

import * as GTM from '../../utils/gtm';

import actions from "./actions";
import appActions from "../app/actions";

import AuthService from "@featurefm/common/services/auth";
import {getUserAccountName} from "@featurefm/common/helpers/mixpanel";

const SKIP_CHECK = [];

// eslint-disable-next-line no-unused-vars
const RESTRICTED = [ "/artists-onboarding", "/labels-onboarding" ];
const REVERSE_RESTRICTED = [ "/login", "/signup", "/forgot" ];
const RESTRICT_COMPLETED = [ "/artists-onboarding", "/labels-onboarding" ];

/**
 * Returns react-router location
 *
 * @param {object} state - redux state
 */
export const getLocation = state => {
  return state.router.location;
};

/**
 * @param {object} state - redux state
 */
export const getPrevLocation = state => {
  return state.App.prevLocation;
};

/**
 * @param {object} state - redux state
 */
export const getCurrentUser = state => {
  return state.Auth.user;
};

export const getInvitationOriginalHash = state => {
  return state.AcceptInvitation.originalHash;
};

/**
 * This one is probably temprorary
 * because of cookies being locked
 * with "HTTP only"
 *
 * I tried to inlcude actions.AUTH_SUCCESS here,
 * but it never runs because of redirect :)
 */
export function* setUser() {
  yield takeEvery([actions.SET_USER], function({ user }) {
    if ( user ) localStorage.setItem("uuid", user.uuid || user.id);
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const currentLocation = yield select(getLocation);

    //  Don't check on some routes
    if ( SKIP_CHECK.includes( currentLocation.pathname ) ) {
      yield put({ type: actions.SET_AUTH_LOADED });
      return;
    }

    const authResponse = yield call( AuthService.auth );

    //  If user is authorized, get user info
    if (authResponse.uuid) {

      yield put({ type: actions.AUTH_SUCCESS });

      yield put({
        type: actions.SET_BASIC_USER_DATA,
        user: authResponse
      });

      if ( !REVERSE_RESTRICTED.includes( currentLocation.pathname ) ) {
        yield put({ type: actions.GET_USER });
      } else {
        yield put({ type: actions.SET_AUTH_LOADED });
        yield put({ type: appActions.SET_LOADED });
      }
    } else {
      yield put({
        type: actions.SET_USER_ERROR,
        error: "Not authorized"
      });

      yield put({ type: actions.SET_AUTH_LOADED });
      yield put({ type: appActions.SET_LOADED });
    }
  });
}

function* redirectToWeb(dest) {
  const invitationOriginalHash = yield select(getInvitationOriginalHash);
  const impersonate = yield select((state) => state.App.impersonate);

  if (invitationOriginalHash) {
    const hrefContainsQueryParams = !!window.location.search;
    const hrefContainsDest = window.location.href.indexOf('?dest=') !== -1 || window.location.href.indexOf('&dest=') !== -1;
    const hrefContainsImpersonate = window.location.href.indexOf('?impersonate=') !== -1 || window.location.href.indexOf('&impersonate=') !== -1;

    let qsSuffix = '';
    if (!hrefContainsDest && dest) {
      qsSuffix += `dest=${encodeURIComponent(dest)}`;
    }
    if (qsSuffix) {
      qsSuffix += '&';
    }
    if (!hrefContainsImpersonate && impersonate) {
      qsSuffix += `impersonate=${encodeURIComponent(impersonate)}`;
    }

    // here the user is already logged-in,
    // so refreshing the login URL will process the accept-invitation flow
    // we do **not** go through handleRedirectToFfm saga here, as we don't want to impersonate yet,
    // only refresh the page while retaining the query params
    const qsSuffixDelimiter = hrefContainsQueryParams ? '&' : '?';
    window.location.href = window.location.href + (qsSuffix ? qsSuffixDelimiter : '') + qsSuffix;
  }
  else {
    //  Redirect to the dest or main app
    yield put({
      type: appActions.HANDLE_REDIRECT_TO_FFM,
      url: dest || (FFM_CONSOLE_NEXT_WEB_URL + FFM_CONSOLE_NEXT_REDIRECT_PATH),
    });
  }
}

export function* login() {
  yield takeEvery(actions.LOGIN, function*({ username, password, dest }) {
    yield put({
      type: actions.SET_AUTH_LOADING
    });

    const stateDest = yield select((state) => state.App.dest);
    const impersonate = yield select((state) => state.App.impersonate);
    const user = yield call(AuthService.login, { username, password });

    if (user.error) {
      yield put({
        type: actions.SET_AUTH_ERROR,
        targetEmail: username,
        error: user.error
      });
    } else {
      GTM.login( username, { method: 'email' });
      GTM.identifyForError( username );
      mixpanel.identify(user.uuid);

      if (!impersonate) {
        // refreshing the profile properties upon login will be fine most of the time,
        // except for when a user stops the process mid-onboarding,
        // which will cause the refresh to be performed on partial data,
        // and then wait at least an hour until the complete data is refreshed.
        // NOTE: calling this before impersonation will cause a hijack error,
        // because by the time the data is returned, the user is already impersonated.
        void mixpanel.refreshUserProfileProperties();
      }

      yield call(redirectToWeb, dest || stateDest);
    }
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    yield put({ type: actions.SET_AUTH_LOADING });
    yield put({ type: appActions.SET_LOADING });

    const response = yield call(AuthService.logout);

    if (response.error) {
      yield put({
        type: actions.SET_AUTH_ERROR,
        error: response.error
      });
    } else {
      GTM.logout();

      yield put( push({
        pathname: '/login'
      }) );
    }

    yield put({ type: actions.AUTH_RESET });
    yield put({ type: actions.SET_AUTH_LOADED });
    yield put({ type: appActions.SET_LOADED });
  })
}

export function* register() {
  yield takeEvery(actions.REGISTER, function*({ form }) {
    yield put({
      type: actions.SET_AUTH_LOADING
    });

    const response = yield call(AuthService.register, form);

    if (response.error) {
      yield put({
        type: actions.SET_AUTH_ERROR,
        targetEmail: (form || {}).email,
        error: response.error
      });
    } else {
      //  Make sure user is logged in and can continue
      yield call(AuthService.login, {
        username: form.email,
        password: form.password
      });

      const basic = yield call(AuthService.auth);
      const user = yield call(AuthService.user);

      if ( user ) {
        yield put({
          type: actions.SET_USER,
          user: user.data
        });

        yield put({
          type: actions.SET_BASIC_USER_DATA,
          user: basic,
        });

        mixpanel.identify(basic.uuid);
        // note: we don't call mixpanel.refreshUserProfileProperties(),
        // this will only be done after onboarding.
        // otherwise we'll have to wait at least an hour for the actual data to be refreshed.

        mixpanel.registerSuperProperties({
          '$email': user.data.email,
          '$name': getUserAccountName(user.data),
          'Plan Name': user.data.plan,
          'Account Name': getUserAccountName(user.data),
          /*
          // can only be done after onboarding
          ...(user.data.role === 'artist' ? {
            'Artist Name': '',
          } : {}),
           */
        });

        mixpanel.track('Registered', {
          'Method': 'Email',
          'Validation Errors': form.aggregatedErrors || [],
          'Validation Errors Count': (form.aggregatedErrors || []).length,
        });
      }

      GTM.signup(form.email, { method: 'email', userType: form.userType });

      yield put({
        type: actions.REGISTER_SUCCESS
      });

      const cookies = new Cookies();
      cookies.set('selectedOnBoardingPlan', form.plan || 'unknown', {
        path: '/',
        maxAge: 60 * 60, // 1 hour
        domain: FFM_COOKIE_DOMAIN,
      });

      try {
        const eventId = uuidv4();
        if (typeof fbq !== 'undefined') {
          // eslint-disable-next-line no-undef
          fbq('track', 'CompleteRegistration', {
            userType: form.userType,
            variant: form.variant,
          }, {eventID: eventId});
        }

        const cookieValue = (name) => document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';
        const fbc = cookieValue('_fbc') || null;
        const fbp = cookieValue('_fbp') || null;

        const axios = new ExtendedAxios({baseURL: FFM_CONSOLE_API_URL});
        yield axios.post( '/facebook-conversion', {
          name: 'CompleteRegistration',
          fbc,
          fbp,
          eventId,
          userType: form.userType,
          variant: form.variant,
        })
      } catch (ex) {
        console.error("cannot send initiate checkout event to facebook", ex)
      }

      if ( form.userType === "artist" ) {
        //  Redirect to Artist Onboarding
        yield put( push({
          pathname: '/artists-onboarding',
          search: `${form.currency ? '?currency=' + encodeURIComponent(form.currency) : ''}`,
        }) );

        yield put({ type: actions.SET_AUTH_LOADED });
      } else {
        //  Redirect to Labels Onboarding
        yield put( push({
          pathname: '/labels-onboarding',
          search: `${form.currency ? '?currency=' + encodeURIComponent(form.currency) : ''}`,
        }) );

        yield put({ type: actions.SET_AUTH_LOADED });
      }
    }
  });
}

export function* setAuthError() {
  yield takeEvery([actions.SET_AUTH_ERROR, actions.SET_USER_ERROR], function() {
    localStorage.removeItem('uuid');
  })
}

/**
 * Trying to do the same thing here as
 * was done in Riot build
 */
export function* facebookLogin() {
  yield takeEvery(actions.FB_LOGIN, function({ role, forceStubs, dest }) {
    const redirectUri = addProtocolToUrl(FFM_CONSOLE_API_URL) + "/fblogin";
    const oktaStateStruct = { role };

    if (forceStubs) {
      oktaStateStruct.forceStubs = forceStubs;
    }

    if (dest) {
      oktaStateStruct.dest = dest;
    }

    /*const oktaLoginUrl = `${OKTA_URL}/oauth2/v1/authorize?idp=${OKTA_IDP}&client_id=${OKTA_CLIENTID}&response_type=code&response_mode=query&scope=openid&redirect_uri=${redirectUri}&state=${encodeURIComponent(
      JSON.stringify(oktaStateStruct)
    )}&nonce=""`;*/

    const facebookLoginUrl = `https://www.facebook.com/v15.0/dialog/oauth?client_id=${FACEBOOK_APP}&redirect_uri=${`${FFM_CONSOLE_API_URL}/fblogin`}&scope=email,public_profile&state=${encodeURIComponent(
        JSON.stringify(oktaStateStruct)
    )}`

    //  This was commented like this in CrowdFund
    //
    //  > "Todo - consult Stots where is the real done action?"
    //
    //  This likely means that we need to move this tracking code
    //  to the part where login is confirmed, but for compatibility
    //  reasons I'll keep it here – most likely this is an expected
    //  behavior at this point.
    GTM.login('no email', { method: 'fb' });
    GTM.identifyForError('FB - no email');

    window.open(facebookLoginUrl, "_self");
    /*window.open(oktaLoginUrl, "_self");*/
  });
}

export function* soundCloudLogin() {
  yield takeEvery(actions.SC_LOGIN, function({ role, forceStubs, dest }) {
    //const redirectUri = addProtocolToUrl(FFM_CONSOLE_API_URL) + "/sclogin";

    GTM.login('no email', { method: 'sc' });
    GTM.identifyForError('SC - no email');

    const loginUrl = `https://api.soundcloud.com/connect?client_id=${SOUNDCLOUD_CLIENT}&redirect_uri=${SOUNDCLOUD_REDIRECT}&state=${encodeURIComponent(JSON.stringify({ logoffOnError: true }))}&response_type=code`

    window.open(loginUrl, "_self");
  });
}

export function* resetPassword(params) {
  yield takeEvery(actions.RESET_PASSWORD, function*({ email: username }) {
    yield put({
      type: actions.SET_AUTH_LOADING
    });

    const response = yield call(AuthService.resetPassword, username);

    if (response.error) {
      yield put({
        type: actions.SET_AUTH_ERROR,
        targetEmail: username,
        error: response.error
      });
    } else {
      yield put({
        type: actions.RESET_SUCCESS
      });

      yield put({
        type: actions.SET_AUTH_LOADED
      });
    }
  });
}

export function* getUser() {
  yield takeEvery(actions.GET_USER, function*() {
    yield put({
      type: actions.SET_USER_LOADING,
    });

    const currentLocation = yield select(getLocation);
    const user = yield call(AuthService.user);
    const basic = yield call(AuthService.auth);

    if (user.error) {
      yield put({
        type: actions.SET_USER_ERROR,
        error: user.error
      });

      yield put({ type: actions.SET_AUTH_LOADED });
      yield put({ type: appActions.SET_LOADED });

      yield put(push({
        pathname: '/login'
      }));
    } else {
      if ( RESTRICT_COMPLETED.includes( currentLocation.pathname ) && parseInt( user.data.registrationStage, 10 ) === 1 ) {
        // todo: if invitationHash ????
        const dest = yield select((state) => state.App.dest);
        yield put({ type: appActions.REDIRECT_TO_CONSOLE_NEXT, dest });
        return;
      }

      yield put({
        type: actions.SET_USER,
        user: user.data
      });

      yield put({
        type: actions.SET_BASIC_USER_DATA,
        user: basic,
      });

      yield put({ type: actions.SET_AUTH_LOADED });
      yield put({ type: appActions.SET_LOADED });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(login),
    fork(logout),
    fork(checkAuthorization),
    fork(register),
    fork(resetPassword),
    fork(facebookLogin),
    fork(setUser),
    fork(soundCloudLogin),
    fork(getUser),
    fork(setAuthError)
  ]);
}
